import { render, staticRenderFns } from "./ClientBarPopup.vue?vue&type=template&id=12034174&scoped=true"
import script from "./ClientBarPopup.vue?vue&type=script&lang=js"
export * from "./ClientBarPopup.vue?vue&type=script&lang=js"
import style0 from "./ClientBarPopup.vue?vue&type=style&index=0&id=12034174&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12034174",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12034174')) {
      api.createRecord('12034174', component.options)
    } else {
      api.reload('12034174', component.options)
    }
    module.hot.accept("./ClientBarPopup.vue?vue&type=template&id=12034174&scoped=true", function () {
      api.rerender('12034174', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ClientBarPopup.vue"
export default component.exports