import httpClient from "@/helpers/httpClient";

const basePath = "play";

export function getHeartbeatSettings() {
  return httpClient().get(`${basePath}/heartbeat/settings`);
}

export function heartbeat(data) {
  return httpClient().post(`${basePath}/heartbeat`, data);
}

export function openGameSession(data) {
  return httpClient().post(`something/here`, data);
}
