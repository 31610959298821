<template>
  <div class="h-full relative" :key="$route.params.barName">
    <div id="iframe-room-parent" class="relative h-full" v-if="openRoom">
      <iframe
        class="h-full w-full"
        :src="virtualRoomUrl"
        frameborder="0"
        crossorigin
        ref="iframe-room"
        id="iframe-room"
      ></iframe>
      <div
        class="fixed w-full h-full top-0 left-0 flex items-center justify-center"
        v-if="iFrameAuthFailed"
      >
        <div class="bg-gray-800 rounded-xl p-16">
          <p class="uppercase pb-4 font-medium">
            Non sei loggato correttamente
          </p>
          <button
            class="w-full p-3 rounded-xl h-fit bg-secondary font-medium"
            @click="logout()"
          >
            Pagina di accesso
          </button>
        </div>
      </div>

      <div
        class="fixed w-full h-full top-0 left-0 flex items-center justify-center"
        v-if="iFrameError"
      >
        <div class="bg-gray-800 rounded-xl p-16">
          <p class="uppercase pb-4 font-medium">La sala non è disponibile</p>
          <button
            class="w-full p-3 rounded-xl h-fit bg-secondary font-medium"
            @click="$router.push({ name: 'SlotsBar' })"
          >
            Apri alla mappa
          </button>
        </div>
      </div>
    </div>
    <div
      id="iframe-game-parent"
      class="absolute top-0 w-full h-full"
      v-if="openGame"
    >
      <iframe
        v-if="gameData.link"
        class="h-full w-full"
        :src="gameData.link"
        frameborder="0"
        crossorigin
        ref="iframe-game"
        id="iframe-game"
      ></iframe>
      <iframe
        v-else
        class="h-full w-full"
        :srcdoc="gameData.html"
        frameborder="0"
        crossorigin
        ref="iframe-game"
        id="iframe-game"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { getCredit } from "@/api/creditRepository";
import {
  getHeartbeatSettings,
  heartbeat,
  openGameSession,
} from "@/api/playRepository";
import { getVrClient } from "@/api/vrRepository";
import tokensRepository from "@/api/tokensRepository";
import iPhoneSetup from "@/mixins/iPhoneSetup";
import axios from "axios";
import Cookies from "js-cookie";
import { getBarInfo } from "../api/barsRepository";

export default {
  name: "ClientBarPopup",

  mixins: [iPhoneSetup],

  data() {
    return {
      iframeWindow: null,
      iFrameError: null,
      iFrameAuthFailed: false,
      cashback: null,
      iframeReadyEvent: null,
      gameData: null,
      heartbeatSettings: null,
      heartbeatInterval: null,
      heartbeatData: null,
      retryCount: null,
      openGame: false,
      openRoom: true,
      inDemo: false,
      virtualRoomUrl: null,
    };
  },

  watch: {
    "$route.params": function () {
      if (
        !Object.keys(this.$route.query).includes("playing") ||
        this.$route.query.playing !== "true"
      ) {
        console.log("$route.params changed - calling gameExit");
        this.gameExit();
      } else {
        console.log("else");
        if (!this.openGame) {
          this.cleanUpQuery();
        }
      }
    },
  },

  created() {
    getVrClient(this.$route.params.barName).then((url) => {
      this.virtualRoomUrl = url;
    });

    this.cleanUpQuery();
    // this.$router.replace({ query: null });
    // this.$emit("regionPopUpVisibility", false);
    getHeartbeatSettings().then((res) => {
      this.heartbeatSettings = res.data;
    });
  },

  mounted() {
    console.log("HEEEEEEEEEEELP - THIS IS MOUNTED IN CLIENT BAR POPUP");

    this.iframeWindow = this.$refs["iframe-room"].contentWindow;
    window.onbeforeunload = null;
    this.setupEventListeners();

    window.onpopstate = this.windowPopstateHandler;

    this.$store.dispatch("socket/emitChangeLocation", [
      "ROOM",
      {
        roomSlug: this.$route.params.barName,
      },
    ]);

    // this.$socket.emit("changeLocation", "ROOM", {
    //   roomSlug: this.$route.params.barName,
    // });
    this.$root.$on(
      "closeEverythingInsideVRIframe",
      this.closeEverythingInsideVRIframeHandler
    );
  },

  methods: {
    async sendSessionInfoHandler(data) {
      data = {
        ...data,
        ...this.heartbeatData,
      };

      console.log("------------- data ----------------");
      console.log(data);

      //TODO there is a sendSessionInfoPolling method down bellow

      console.log("sendSessionInfoHandler");
      try {
        const res = await this.sendSessionInfo(data, 10, 1000);

        console.log("res", res);
      } catch (err) {
        console.log("err", err);
      }

      //   const retries = 10;
      //   let success = false;

      //   while (retries-- > 0 && !success) {
      //     openGameSession()
      //       .then((res) => {
      //         console.log("success");
      //         success = true;
      //       })
      //       .catch((err) => conso le.error(err));
      //   }
    },

    async wait(timeInMs) {
      console.log("Waiting ...");
      return new Promise((resolve) => setTimeout(() => resolve(), timeInMs));
    },

    async sendSessionInfo(data, numberOfTries, timeout) {
      console.log("sendSessionInfo");
      console.log("data", data);
      console.log("numberOfTries", numberOfTries);
      console.log("timeout", timeout);
      if (numberOfTries <= 0) {
        console.log("Couldn't send session Info");
        return "Couldn't send session Info";
      }
      try {
        const result = await openGameSession(data);
        console.log("result", result);
        return result;
      } catch (err) {
        console.log("calling wait");
        await this.wait(timeout); // wait for the defined timeout before recurring
        console.log("calling itself again");
        return this.sendSessionInfo(data, numberOfTries - 1, timeout);
      }
    },

    sendSessionInfoPolling(data, maxRetries = 10) {
      // const attemptRequest = (attemptsLeft) => {
      //   openGameSession(data)
      //     .then((res) => {
      //       console.log("resolving promise");
      //       resolve(res);
      //     })
      //     .catch(async (err) => {
      //       console.log("catching promise");

      //       // Check error codes and retry if 503 Service Unavailable
      //       if (err.response.status === 503 && attemptsLeft > 0) {
      //         console.log("dispatching retry attempt", attemptsLeft);
      //         let timeout = 1000;
      //         setTimeout(() => {
      //           attemptRequest(attemptsLeft - 1);
      //         }, timeout);
      //       } else {
      //         reject(err);
      //       }
      //     });
      // };

      // attemptRequest(maxRetries);

      return new Promise((resolve, reject) => {
        openGameSession(data)
          .then((res) => {
            console.log("resolving promise");
            resolve(res);
          })

          .catch(async (err) => {
            console.log("catching promise");

            //TODO check error codes with Reis
            if (err.response.status === 404) {
              console.log("despatching ele proprio");
              let timeout = 1000;
              setTimeout(async () => {
                this.sendSessionInfoPolling().then((res) => {
                  resolve(res);
                });
              }, timeout);
            } else {
              reject(err);
            }
          });
      });
    },

    closeEverythingInsideVRIframeHandler() {
      this.iframeReadyEvent.source.postMessage(
        { name: `closeAllMenus` },
        this.iframeReadyEvent.origin
      );
      this.$root.$emit("closeChat");
      // this.iframeWindow.source.postMessage({ name: `closeAllMenus` }, "*");
    },

    closeMenu() {
      this.$root.$emit("closeMenu");
    },
    async call(url) {
      /*       const requestData = {}; // Replace with your request data

      // Make the request using navigator.sendBeacon()
      const isSent = navigator.sendBeacon(url, JSON.stringify(requestData));

      if (isSent) {
        console.log("Request sent using sendBeacon");
      } else {
        // If sendBeacon was not successful, fallback to traditional request */
      try {
        await axios.post(url);
        console.log("Request sent using axios");
      } catch (error) {
        console.error("Error occurred during the request", error);
      }
      /*    } */
      console.log("called gameOver endpoint");
    },
    async logoutCleanUp() {
      this.$store.dispatch("auth/cleanUpAuth");
      this.$store.dispatch("socket/destroySocket");
    },

    logout() {
      this.logoutCleanUp().then(() => {
        this.$router.push("/login");
      });
    },

    startHeartbeat(retry = false) {
      // Clear any existing interval to ensure only one heartbeat is active at a time
      this.stopHeartbeat();
      const intervalMilliseconds =
        (retry
          ? this.heartbeatSettings.retryFrequency
          : this.heartbeatSettings.frequency) * 1000;

      // Start the heartbeat by scheduling the POST request at the defined interval
      this.heartbeatInterval = setInterval(() => {
        // Execute the POST request here
        this.heartbeatExecution();
      }, intervalMilliseconds);
    },

    async stopHeartbeat(resetData = false) {
      // Clear the interval if it is currently running
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
      }
      if (resetData) {
        this.retryCount = null;
        this.heartbeatData = null;
      }
    },

    heartbeatExecution() {
      heartbeat(this.heartbeatData)
        .then(() => {
          console.log("Performed Heartbeat with success");
          if (this.retryCount) {
            this.retryCount = null;
            this.startHeartbeat();
          }
        })
        .catch((e) => {
          console.log("Heartbeat failed");
          if (e.response && e.response.status == 404) {
            this.stopHeartbeat(true);
            return;
          }
          if (!this.retryCount) {
            this.retryCount = 1;

            this.startHeartbeat(true);
          } else {
            this.retryCount++;
            if (this.retryCount > this.heartbeatSettings.maxRetries) {
              this.stopHeartbeat(true);
            }
          }
        });
    },

    windowPopstateHandler(event) {
      const url = new URL(window.location);
      if (!url.searchParams.has("ifrm")) {
        this.iframeWindow.postMessage({ name: "menuVLT:invisible" }, "*");
      }
    },

    postMessageHandler(event) {
      console.log("postMessageHandler");
      let origins = [this.virtualRoomUrl];

      if (this.gameData && this.gameData.link) {
        const origin = new URL(this.gameData.link).origin;
        origins.push(origin);
      }

      const eventName = event.data.toString();

      console.log(eventName);

      console.log("EVENTO ----------------------");
      console.log(" ----------------------");
      console.log(event);

      // has to stay here, before security check
      // bcs some events of close come from the child of the iframe hosting
      if (
        /*
        -----------------------------------------
        --- this code bellow is ISIBET legacy ---
        -----------------------------------------
        */
        /*
        eventName === "close" ||
        eventName === "closePopup" ||
        eventName === "stopFun" ||
        eventName.toLowerCase().includes("stop") ||
        */
        event &&
        event.data.operation
      ) {
        console.log("EVENTO ---------------------- operation");
        console.log(event.data.operation);

        /* EXALOGIC - OPENING GAME */
        if (event.data.operation.toLowerCase().includes("info_game##")) {
          console.log("antes dos parse");
          const data = JSON.parse(event.data.operation.split("INFO_GAME##")[1]);
          console.log("-------------------------------");
          console.log("HEY HEY HEY - ISTO É A INFO DA SESSAO");
          console.log(data);
          this.sendSessionInfoHandler(data);
        }

        /* EXALOGIC - CLOSING GAME */
        if (
          event.data.operation.toLowerCase().includes("killgame#") ||
          event.data.operation.toLowerCase().includes("kill_window#")
        ) {
          console.log("received events of chiudi");
          console.log(event.operation);
          this.gameExit();
        }
      }

      // SECURITY - checking if the post message comes from virtual rooms
      if (!origins.includes(event.origin)) {
        console.log(origins);
        console.log(event.origin);
        return;
      }

      if (event.data.data == "openChat") {
        this.$root.$emit("openChat");
      }

      if (event.data.data == "fullscreen") {
        this.$root.$emit("toggled");
      }

      if (event.data.data == "barName") {
        const barName = event.data.name;
        const barSlug = this.$route.params.barName;
        this.$root.$emit("barNameUpdate", { barName, barSlug });
      }

      if (event.data.data == "ready") {
        this.iframeReadyEvent = event;
        this.postMessageSocketHandshake();
        if (!this.iframeReadyEvent) this.updateCashback();
        this.$emit("isInGameUpdate", false);
        this.cleanUpQuery();
      }

      // event.data.data == "play" ||
      if (event.data.data == "gameOver") {
        this.updateUIBalance();
        this.updateUITokens();
      }

      if (event.data.data == "openGameInfo") {
        this.gameData = event.data.launchInfo;
        this.heartbeatData = event.data.info;
      }

      if (event.data.data == "openGame") {
        console.log("received openGame event");
        if (
          this.gameData &&
          (this.gameData.link || this.gameData.html) &&
          this.gameData.gameOverUrl
        ) {
          this.openGame = true;
          this.openRoom = false;

          this.$store.dispatch("socket/emitChangeLocation", [
            "GAME",
            {
              roomSlug: this.$route.params.barName,
              gameId: this.gameData.gameId,
            },
          ]);
          // heartbeat only on real game launches
          this.startHeartbeat();
          //inject params to url
          this.$router.push({ query: { playing: true } });
          this.$emit("isInGameUpdate", true);
          this.$root.$emit("gameIframeOpened", true);
        } else {
          console.log("missing data to open game");
        }
      }

      if (event.data.data == "openGameDemo") {
        console.log("received openGameDemo event");
        if (this.gameData && (this.gameData.link || this.gameData.html)) {
          this.$router.push({ query: { playing: true } });
          this.openGame = true;
          this.openRoom = true;
          this.inDemo = true;
          //TODO socket change location emit here - DEAL WITH THIS
          this.$emit("isInGameUpdate", true);
          this.$root.$emit("gameIframeOpened", true);
        } else {
          console.log("missing data to open game demo");
        }
      }

      if (
        event.data.data == "reservation" ||
        event.data.data == "cancelReservation" ||
        event.data.data == "playReservation"
      ) {
        this.updateUITokens();
      }

      if (event.data.data == "auth:failed") {
        this.iFrameAuthFailed = true;
      }

      if (event.data.data == "error") {
        this.iFrameError = true;
      }

      // pushes new state (openVLT) to prevent browser back button leaving the virtual-room
      if (event.data.data == "vltMenuOpen") {
        const url = new URL(window.location);
        if (!url.searchParams.has("ifrm")) {
          url.searchParams.set("ifrm", 1);
          history.pushState({ page: 1 }, "", url);
        }
      }

      // replaces state to re enable browser back button
      if (event.data.data == "vltMenuClose") {
        const url = new URL(window.location);
        if (url.searchParams.has("ifrm")) {
          url.searchParams.delete("ifrm");
          history.pushState({ page: 1 }, "", url);
        }
      }
    },

    updateCashback() {
      let barName = this.$route.params.barName;
      if (barName) {
        getBarInfo(barName).then((response) => {
          let cashback = response.data.cashback;
          this.iframeReadyEvent.source.postMessage(
            { name: `cashback:${cashback}` },
            this.iframeReadyEvent.origin
          );
        });
      }
    },

    async pageDestroyHandler(event) {
      event.preventDefault();
      event.returnValue = "";
      console.log("pageDestroyHandler");
      this.removeAllEventListeners();
      await this.gameExit(false);
    },

    updateUIBalance() {
      getCredit(this.$store.getters["userInfo/getUserId"]).then((res) => {
        console.log(res.data);
        this.updateCreditPostMessage(res.data.credit);
        this.$store.commit("userInfo/mutateCredit", res.data);
      });
    },

    updateTokensPostMessage(tokens) {
      if (
        this.openRoom &&
        this.iframeReadyEvent &&
        this.iframeReadyEvent.source
      ) {
        this.iframeReadyEvent.source.postMessage(
          { name: `tokens:${tokens}` },
          this.iframeReadyEvent.origin
        );
      }
    },
    updateCreditPostMessage(credit) {
      if (
        this.openRoom &&
        this.iframeReadyEvent &&
        this.iframeReadyEvent.source
      ) {
        this.iframeReadyEvent.source.postMessage(
          { name: `credit:${credit}` },
          this.iframeReadyEvent.origin
        );
      }
    },

    updateUITokens() {
      tokensRepository
        .getCredit(this.$store.getters["userInfo/getUserId"])
        .then((res) => {
          console.log(res.data);
          this.updateTokensPostMessage(res.data.credit);
          this.$store.commit("userInfo/mutateTokens", res.data);
        });
    },

    async gameExit(updateBalance = true) {
      const gameFrame = document.getElementById("gameIframe");
      if (gameFrame && gameFrame.contentWindow) {
        console.log("send customclose");
        gameFrame.contentWindow.postMessage("customclose", "*");
      }

      const gameOverUrl =
        this.gameData && this.gameData.gameOverUrl
          ? this.gameData.gameOverUrl
          : null;
      this.gameData = null;
      /*    if (document.webkitFullscreenElement || document.fullscreenElement) {
        await document.exitFullscreen();
      } */

      if (this.openRoom && this.inDemo) {
        this.$refs["iframe-room"].contentWindow.postMessage(
          { name: "exitGameDemo" },
          "*"
        );
        this.inDemo = false;
      }
      this.openGame = false;
      this.openRoom = true;

      this.$store.dispatch("socket/emitChangeLocation", [
        "ROOM",
        {
          roomSlug: this.$route.params.barName,
        },
      ]);
      this.$root.$emit("gameIframeOpened", false);
      if (gameOverUrl) {
        this.stopHeartbeat();
        if (updateBalance) {
          this.updateUIBalance();
          this.updateUITokens();
        }
        await this.call(gameOverUrl);
      }

      this.cleanUpQuery();
    },

    cleanUpQuery() {
      console.log("cleaning up query");
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: null });
      }
    },

    postMessageSocketHandshake() {
      const data = {
        name: "handshake",
        data: {
          numContract: this.$store.getters["auth/getNumContract"],
          barName: this.$route.params.barName,
          playerName: this.$store.getters["userInfo/getNickname"],
          figureId: this.$store.getters["userInfo/getFigureId"],
          balance: {
            credit: this.$store.getters["userInfo/getCredit"],
            tokens: this.$store.getters["userInfo/getTokens"],
          },
          options: {
            fullscreenActivated:
              document.webkitFullscreenElement || document.fullscreenElement
                ? true
                : false,
          },
        },
      };
      console.log("sending data");
      console.log(data);

      // is this IF overkill ? the idea remains here
      //if(!!data.data.numContract) {
      this.iframeReadyEvent.source.postMessage(
        data,
        this.iframeReadyEvent.origin
      );
    },

    setupEventListeners() {
      console.log("setupEventListeners");
      window.addEventListener("beforeunload", this.pageDestroyHandler, {
        capture: true,
      });
      document.addEventListener(
        "visibilitychange",
        this.visibilityChangeHandler
      );
      //Event Listener for Iframe
      window.addEventListener("message", this.postMessageHandler);
      window.addEventListener("onmessage", this.postMessageHandler);
    },

    removeAllEventListeners() {
      this.$root.$off(
        "closeEverythingInsideVRIframe",
        this.closeEverythingInsideVRIframeHandler
      );
      window.removeEventListener("beforeunload", this.pageDestroyHandler);
      //window.removeEventListener("pagehide", this.pageDestroyHandler);
      window.removeEventListener("message", this.postMessageHandler);
      window.removeEventListener("onmessage", this.postMessageHandler);
      document.removeEventListener(
        "visibilitychange",
        this.visibilityChangeHandler
      );
    },
    pageVisibleHandler() {
      if (this.openGame) {
        this.heartbeatData = { ...this.heartbeatData, state: undefined };
      }
      if (this.openRoom) {
        this.$refs["iframe-room"].contentWindow.postMessage(
          { name: "visibilityChange:visible" },
          "*"
        );
      }
    },

    pageNotVisibleHandler() {
      if (this.openGame) {
        this.heartbeatData = { ...this.heartbeatData, state: "invisible" };
      }
      if (this.openRoom) {
        this.$refs["iframe-room"].contentWindow.postMessage(
          { name: "visibilityChange:notVisible" },
          "*"
        );
      }
    },

    visibilityChangeHandler() {
      if (document.visibilityState === "visible") {
        this.pageVisibleHandler();
      } else {
        this.pageNotVisibleHandler();
      }
    },
  },

  async beforeDestroy() {
    this.$emit("fullscreenBtnVisibility", true);
    console.log("before destroy");
    this.removeAllEventListeners();
    await this.gameExit();
    this.$root.$emit("barNameUpdate", { barName: null, barSlug: null });
  },
};
</script>

<style scoped lang="postcss">
#iframe-room-parent,
#iframe-game-parent {
  /* margin-top: 9vh; */
  /* margin-bottom: 11vh; */
  height: 100%;
  @apply w-screen bg-black bg-opacity-70;

  /*   @screen xs {
    margin-top: 17.4vh;
    height: 82.6vh;
    @apply mb-0;
  } */

  /* @screen md {
      @apply relative;
   } */

  /*  @screen lg {
    margin-top: 10vh;
    height: 90vh;
  } */

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease-in;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

#iframe-room-parent {
  z-index: 0;
}

#iframe-game-parent {
  z-index: 1;
}
</style>
