var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.$route.params.barName, staticClass: "h-full relative" },
    [
      _vm.openRoom
        ? _c(
            "div",
            {
              staticClass: "relative h-full",
              attrs: { id: "iframe-room-parent" },
            },
            [
              _c("iframe", {
                ref: "iframe-room",
                staticClass: "h-full w-full",
                attrs: {
                  src: _vm.virtualRoomUrl,
                  frameborder: "0",
                  crossorigin: "",
                  id: "iframe-room",
                },
              }),
              _vm.iFrameAuthFailed
                ? _c(
                    "div",
                    {
                      staticClass:
                        "fixed w-full h-full top-0 left-0 flex items-center justify-center",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "bg-gray-800 rounded-xl p-16" },
                        [
                          _c(
                            "p",
                            { staticClass: "uppercase pb-4 font-medium" },
                            [_vm._v(" Non sei loggato correttamente ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "w-full p-3 rounded-xl h-fit bg-secondary font-medium",
                              on: {
                                click: function ($event) {
                                  return _vm.logout()
                                },
                              },
                            },
                            [_vm._v(" Pagina di accesso ")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.iFrameError
                ? _c(
                    "div",
                    {
                      staticClass:
                        "fixed w-full h-full top-0 left-0 flex items-center justify-center",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "bg-gray-800 rounded-xl p-16" },
                        [
                          _c(
                            "p",
                            { staticClass: "uppercase pb-4 font-medium" },
                            [_vm._v("La sala non è disponibile")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "w-full p-3 rounded-xl h-fit bg-secondary font-medium",
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({ name: "SlotsBar" })
                                },
                              },
                            },
                            [_vm._v(" Apri alla mappa ")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.openGame
        ? _c(
            "div",
            {
              staticClass: "absolute top-0 w-full h-full",
              attrs: { id: "iframe-game-parent" },
            },
            [
              _vm.gameData.link
                ? _c("iframe", {
                    ref: "iframe-game",
                    staticClass: "h-full w-full",
                    attrs: {
                      src: _vm.gameData.link,
                      frameborder: "0",
                      crossorigin: "",
                      id: "iframe-game",
                    },
                  })
                : _c("iframe", {
                    ref: "iframe-game",
                    staticClass: "h-full w-full",
                    attrs: {
                      srcdoc: _vm.gameData.html,
                      frameborder: "0",
                      crossorigin: "",
                      id: "iframe-game",
                    },
                  }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }