import httpClient from "@/helpers/httpClient";

export async function getVrClient(barName) {
  if (!!process.env.VUE_APP_VR_ROUTING_SERVICE_URL) {
    const roomName = barName.toLowerCase();
    const res = await httpClient().get(
      `${process.env.VUE_APP_VR_ROUTING_SERVICE_URL}/url?room=${roomName}`
    );
    return res.data;
  }
  return process.env.VUE_APP_VIRTUAL_ROOM_URL;
}
